import React from 'react';
import { PageProps } from 'gatsby';

import data from '~/data/case-studies/lantern';
import { ProjectDTO } from '~/types';
import Layout from '~/components/Layout';
import ProjectHeader from '~/components/ProjectHeader';
import ProjectPlatform, {
  ProjectPlatformData,
} from '~/components/ProjectPlatform';
import ClientDescription, {
  ClientDescriptionData,
} from '~/components/ClientDescription';
import ProjectExcerpt, {
  ProjectExcerptData,
} from '~/components/ProjectExcerpt';
import EstimationCTA from '~/components/EstimationCTA';
import Image from '~/components/Image';
import OtherProjects from '../components/OtherProjects';

import styles from './Lantern.module.scss';

const Lantern: React.FC<PageProps> = ({ location }) => {
  const { generalInfo, client, scope, tldr, appSection, techHighlights } = data;

  const projectHeaderData: ProjectDTO = {
    ...generalInfo,
    img: generalInfo.img,
  };

  const clientDescriptionData: ClientDescriptionData = {
    textShort: client.descriptionShort,
    textLong: client.descriptionLong,
    scope: scope.types,
    techStack: scope.techStack,
  };

  const projectPlatformData: ProjectPlatformData = {
    platforms: data.platforms.types,
    projectName: data.generalInfo.name,
    appStoreUrl: data.generalInfo.appStoreUrl,
    slides: [
      data.platforms.images[0],
      data.platforms.images[1],
      data.platforms.images[2],
    ],
  };

  const projectExcerptData: ProjectExcerptData = { text: tldr };

  return (
    <Layout invertNavigation>
      <header className={styles.header}>
        <ProjectHeader data={projectHeaderData} />
      </header>
      <article className={styles.article}>
        <ClientDescription data={clientDescriptionData} />
      </article>
      <section className={styles.platforms}>
        <ProjectPlatform data={projectPlatformData} />
      </section>

      <article className={styles.articleXl}>
        <ProjectExcerpt data={projectExcerptData} />
      </article>
      <article className={styles.article}>
        <h2 className={styles.articleTitle}>{scope.title}</h2>
        <h3 className={styles.articleSubtitle}>{scope.featuredParagraph}</h3>
        <section className={styles.articleSection}>
          <div
            className={styles.articleText}
            dangerouslySetInnerHTML={{ __html: scope.firstTextBlock }}
          />
          <Image
            img={scope.appImage}
            imgMobile={scope.appImageMobile}
            alt={scope.appImageAlt}
            className={styles.articleImg}
          />
          <div
            className={styles.articleText}
            dangerouslySetInnerHTML={{ __html: scope.secondTextBlock }}
          />
        </section>
      </article>
      <section className={styles.platforms}>
        <ProjectPlatform data={appSection} inOneLine />
      </section>
      <article className={styles.article}>
        <h2 className={styles.articleTitle}>{techHighlights.heading}</h2>
        <ul className={styles.articleList}>
          {techHighlights.listItems.map((item) => (
            <li key={item} className={styles.articleListItem}>
              {item}
            </li>
          ))}
        </ul>
        <div
          className={styles.articleText}
          dangerouslySetInnerHTML={{ __html: techHighlights.paragraph }}
        />
      </article>
      <article className={styles.cta}>
        <EstimationCTA />
      </article>
      <article>
        <OtherProjects curretPageUrl={location.pathname} />
      </article>
    </Layout>
  );
};

export default Lantern;

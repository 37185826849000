import React from 'react';
import classNames from 'classnames';

import { Breakpoint } from '~/constants';
import { ReactComponent as AppStore } from '~/assets/icons/app-store.svg';
import { ReactComponent as GooglePlay } from '~/assets/icons/google-play.svg';
import Carousel, { CarouselSettings } from '~/components/Carousel';

import styles from './ProjectPlatform.module.scss';
import Image from '../Image';

const carouselSettings: CarouselSettings = {
  className: styles.slider,
  rows: 1,
  infinite: false,
  slidesToShow: 3,
  arrows: false,
  draggable: false,
  centerMode: true,
  centerPadding: '60px',
  responsive: [
    {
      breakpoint: Breakpoint.SMALL - 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        focusOnSelect: true,
        draggable: true,
      },
    },
  ],
};

type ProjectPlatformImage = string;

export type ProjectPlatformData = {
  slides: [ProjectPlatformImage, ProjectPlatformImage, ProjectPlatformImage];
  platforms?: string[];
  appStoreUrl?: string;
  googlePlayUrl?: string;
  projectName: string;
};

type Props = {
  data: ProjectPlatformData;
  inOneLine?: boolean;
};

const ProjectPlatform: React.FC<Props> = ({ data, inOneLine }) => {
  const { slides, platforms, appStoreUrl, googlePlayUrl, projectName } = data;

  const label = platforms?.length === 1 ? 'Platform' : 'Platforms';

  return (
    <div className={styles.root}>
      <Carousel {...carouselSettings}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={classNames(styles.sliderItem, {
              [styles.sliderItemCenter]: index === 1 && !inOneLine,
            })}
          >
            <Image img={slide} alt={projectName} className={styles.img} />
          </div>
        ))}
      </Carousel>
      {platforms && (
        <div className={styles.keywordsList}>
          <span className={styles.keywordsLabel}>{label}:&nbsp;</span>
          <span className={styles.keywordsItems}>{platforms.join(', ')}</span>
        </div>
      )}
      <div className={styles.linksContainer}>
        {appStoreUrl && (
          <a
            href={appStoreUrl}
            rel='noopener noreferrer'
            target='_blank'
            className={styles.link}
          >
            <AppStore />
          </a>
        )}
        {googlePlayUrl && (
          <a
            href={googlePlayUrl}
            rel='noopener noreferrer'
            target='_blank'
            className={styles.link}
          >
            <GooglePlay />
          </a>
        )}
      </div>
    </div>
  );
};

export default ProjectPlatform;

import { ProjectCategory } from '~/enums';

import lanternMain from './assets/lantern.jpg';
import lanternMainMobile from './assets/lantern-mobile.png';
import lanternApp1 from './assets/lantern-app-1.jpg';
import lanternApp2 from './assets/lantern-app-2.jpg';
import lanternApp3 from './assets/lantern-app-3.jpg';
import lanternApp4 from './assets/lantern-app-4.jpg';
import lanternApp4Mobile from './assets/lantern-app-4-mobile.png';
import lanternApp5 from './assets/lantern-app-5.jpg';
import lanternApp6 from './assets/lantern-app-6.jpg';
import lanternApp7 from './assets/lantern-app-7.jpg';

import { ProjectPlatformData } from '~/components/ProjectPlatform';

import routes from '~/routes';

const appSection: ProjectPlatformData = {
  projectName: 'Lantern',
  slides: [lanternApp5, lanternApp6, lanternApp7],
};

const lanternCaseStudy = {
  generalInfo: {
    name: 'Lantern',
    nameShort: 'Lantern',
    category: ProjectCategory.ONLINE_DATING,
    summary:
      'Lantern is the first dating app to leverage gamification and technology.',
    appStoreUrl:
      'https://apps.apple.com/us/app/lantern-dating-focused-on-you/id1533737117',
    img: lanternMain,
    imgMobile: lanternMainMobile,
    projectPageUrl: routes.CASE_STUDIES_LANTERN,
    description: '',
  },
  client: {
    descriptionShort:
      'Yehuda Jason Neuman and Caroline Gyllensporre are on a mission to shift online dating from swiping left and right into a game that combines relationship psychology with a weighted algorithm.',
    descriptionLong: `In <a href="https://lanterndating.com/" rel="noopener noreferrer" target="_blank">Lantern</a>, users play by answering questions and are given a chance to match with others who’ve chosen the same answer. The matching algorithm is based on psychology backed by Oprah-featured psychologist, New York Times best-selling author, and Yehuda's father, M Gary Neuman.`,
  },
  scope: {
    types: ['Design, Development'],
    techStack: ['Native iOS (Swift)', 'Django', 'Python'],
    title: 'Our Work',
    featuredParagraph:
      'Working with Lantern, we were involved in the project from the UX wireframing, through backend development, until the iOS app release. ',
    firstTextBlock: `We started by suggesting a structure for the app using wireframes. As the matching algorithm influences the app's logic, we had to make several revisions throughout the design process. In the UI design phase, we focused on creating an intuitive and engaging in-app experience. We were building something completely new, so it was a creative playground and we got to experiment with different approaches before we decided on the best one.`,
    appImage: lanternApp4,
    appImageMobile: lanternApp4Mobile,
    appImageAlt: 'Featured image with application',
    secondTextBlock: `
      <p>At this point, the backend development was kicked off. It was strongly correlated with how the app looked on the screens and how the users would navigate between them. The matching algorithm development was quite a challenge, but being able to turn years of psychological practice into an algorithm that supports the users in their dating journey, was what we call <b>building a technology that makes a difference.</b></p>
      <p>The iOS development focused on top app performance and time efficiency. Some parts required refactoring to MVVM to enable better animations.</p>
      <p>The teams collaborated with Jason and Caroline very closely to ensure the app was in line with their vision in terms of gamification and entertainment aspects, as well as the relationship psychology behind it.</p>
    `,
  },
  platforms: {
    types: ['iOS'],
    images: [lanternApp1, lanternApp2, lanternApp3],
  },
  tldr:
    'Jason and Caroline had a vision for a more authentic and fun dating app experience, and extensive psychological knowledge to back it. Our job was to combine both into an app that would work smoothly and users would love.',
  appSection: appSection,
  techHighlights: {
    heading: 'Tech highlights from our partnership with Lantern:',
    listItems: [
      'Matching algorithm development on back end (Django/Python)',
      'Fast iOS development: Alomafire for network requests, asynchronous programming with RxSwift, refactoring to MVVM for better animations experience',
      'Gamification in UX and UI design',
      '6 months to from the project kick-off to the app release',
    ],
    paragraph: `We're proud that together we managed to deliver such an amazing dating app that will change the lives of many. We are even more excited that the Lantern journey is just beginning.`,
  },
};

export default lanternCaseStudy;
